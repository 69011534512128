<template>
  <Layout :tituloPagina="`Network | DHs | ${modo == 'nuevo' ? 'Nuevo' : 'Edición'}`">
    <div class="row pb-4">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header justify-content-between d-flex align-items-center">
            <h4 class="card-title">
              {{ modo == 'nuevo' ? 'Nuevo' : 'Edición de ' }}
              dispositivo para hogar
            </h4>
            <div>
              <div class="dropdown">
                <a
                  class="dropdown-toggle"
                  href="javascript: void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <eva-icon
                    name="more-horizontal-outline"
                    data-eva-width="20"
                    data-eva-height="20"
                    :class="{
                      'fill-dark': $store.state.layout.layoutMode == 'light',
                      'fill-white': $store.state.layout.layoutMode == 'dark'
                    }"
                  ></eva-icon>
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li>
                    <a
                      href="#"
                      class="dropdown-item"
                      onclick="return false"
                      @click="modo == 'nuevo' ? guardar() : actualizar()"
                      v-if="!bandera_spinner"
                    >
                      <i class="mdi mdi-content-save text-success"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </a>
                    <span class="dropdown-item disabled" v-if="bandera_spinner">
                      <i class="mdi mdi-concourse-ci mdi-spin"></i>
                      {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
                    </span>
                  </li>
                  <li>
                    <a
                      class="dropdown-item" href="#"
                      onclick="return false;" @click="atras()"
                    >
                      <i class="mdi mdi-close text-dark"></i>
                      Cerrar
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <label>Nombre</label>
                <input
                  class="form-control"
                  placeholder="Nombre del DH"
                  name="nombre"
                  v-model="dh.nombre"
                />
              </div>

              <div class="col-md-6">
                <label>Estado</label>
                <select
                  ref="estado"
                  name="id_estado"
                  class="form-select"
                  v-model="dh.id_estado"
                >
                  <option :value="null">
                    Seleccionar
                  </option>
                  <option
                    v-for="estado in estados"
                    :value="estado.id"
                    :key="estado.id"
                  >
                    {{ estado.nombre }}
                  </option>
                </select>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label>Tipo</label>
                <select
                  name="id_tipo"
                  class="form-select"
                  id="imgMarca"
                  v-model="dh.id_tipo"
                >
                  <option :value="null">
                    Seleccionar
                  </option>
                  <option
                    v-for="tipo in tipos"
                    :key="tipo.id"
                    :value="tipo.id"
                  >
                    {{ tipo.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Marca</label>
                <select
                  name="id_marca"
                  class="form-select"
                  v-model="dh.id_marca"
                  @change="
                    dh.id_modelo = null,
                    cargarModelos()
                  "
                >
                  <option :value="null">
                    Seleccionar
                  </option>
                  <option
                    v-for="marca in marcas"
                    :key="marca.id"
                    :value="marca.id"
                  >
                    {{ marca.nombre }}
                  </option>
                </select>
              </div>
              <div class="col-md-4">
                <label>Modelo</label>
                <select
                  name="id_modelo"
                  class="form-select"
                  v-model="dh.id_modelo"
                  :disabled="modelos.length == 0"
                >
                  <option :value="null">
                    Seleccionar
                  </option>
                  <option
                    v-for="modelo in modelos"
                    :key="modelo.id"
                    :value="modelo.id"
                  >
                    {{ modelo.identificador }}
                  </option>
                </select>
              </div>
            </div>

            <br /><br />
            <h4>Credencial de administración</h4>

            <div class="row">
              <div class="col-md-6">
                <label>Usuario</label>
                <input
                  class="form-control"
                  placeholder="Usuario del DH"
                  name="usuario"
                  v-model="dh.usuario"
                />
              </div>
              <div class="col-md-6">
                <label>Clave</label>
                <input
                  class="form-control"
                  name="clave"
                  v-model="dh.clave"
                  placeholder="Clave del DH"
                />
              </div>
            </div>

            <br /><br />
            <h4>Información de red</h4>

            <div class="row">
              <div class="col-md-6">
                <label>IP WAN</label>
                <input
                  class="form-control"
                  name="ip_wan"
                  placeholder="192.168.100.2"
                  v-model="dh.ip_wan"
                />
              </div>
              <div class="col-md-6">
                <label>MAC WAN</label>
                <input
                  class="form-control"
                  name="mac_wan"
                  placeholder="01:02:03:A4:B5:C6"
                  v-model="dh.mac_wan"
                  maxlength="17"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>IP LAN</label>
                <input
                  class="form-control"
                  name="ip_lan"
                  placeholder="192.168.200.1"
                  v-model="dh.ip_lan"
                />
              </div>
              <div class="col-md-6">
                <label>MAC LAN</label>
                <input
                  class="form-control"
                  name="mac_lan"
                  placeholder="01:02:03:A4:B5:D6"
                  v-model="dh.mac_lan"
                  maxlength="17"
                />
              </div>
            </div>

            <br /><br />
            <h4>Señal Wifi</h4>
            <div class="row">
              <div class="col-md-6">
                <label>SSID</label>
                <input
                  class="form-control"
                  name="ssid"
                  v-model="dh.ssid"
                  placeholder="SSID del DH"
                />
              </div>
              <div class="col-md-6">
                <label>Clave</label>
                <input
                  class="form-control"
                  name="clave_wifi"
                  v-model="dh.clave_wifi"
                  placeholder="Clave wifi del DH"
                />
              </div>
            </div>

            <br /><br />

            <h4>Nota del dispositivo</h4>
            <div class="row">
              <div class="col-md-12">
                <textarea
                  class="form-control"
                  placeholder="Nota del dispositivo de hogar"
                  name="nota"
                  rows="5"
                  maxlength="2000"
                  v-model="dh.nota"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div class="text-right">
          <div class="btn-group">
            <button class="btn btn-secondary" @click="atras()">
              <i class="mdi mdi-chevron-left"></i>
              Atrás
            </button>
            <button
              class="btn btn-success"
              @click="modo == 'nuevo' ? guardar() : actualizar()"
              :disabled="bandera_spinner"
            >
              <i
                class="mdi"
                :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
              ></i>
              {{ modo == 'nuevo' ? 'Guardar' : 'Actualizar' }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main"

import DhSrv from '@/services/DhSrv.js'
import ModeloDhsSrv from '@/services/ModeloDhsSrv.js'
import EstadoDhsSrv from '@/services/EstadoDhsSrv.js'
import MarcaDhsSrv from '@/services/MarcaDhsSrv.js'
import TipoDhsSrv from '@/services/TipoDhsSrv.js'
export default {
  name: 'EdicionDh',
  components: { Layout },
  data() {
    return {
      dh: {
        nombre: '',
        usuario: '',
        clave: '',
        ssid: '',
        clave_wifi: '',
        ip_wan: '',
        ip_lan: '',
        mac_wan: '',
        mac_lan: '',
        id_marca: null,
        id_modelo: null,
        id_estado: null,
        id_tipo: null
      },
      dhInicial: {},
      estados: [],
      marcas: [],
      tipos: [],
      modelos: [],
      modo: 'nuevo',
      bandera_spinner: false
    }
  },

  created: function() {
    var self = this

    if (this.$route.path.indexOf('nuevo') == -1) this.modo = 'edicion'

    // Registro de una copia del CPE inicial
    self.dhInicial = Object.assign({}, this.dh)

    // Cargas iniciales
    switch (self.modo) {
      case 'nuevo':
        self.cargarEstados()
        self.cargarMarcas()
        self.cargarTipos()
        break
      case 'edicion':
        self.cargarEstados(function() {
          self.cargarDh(function() {
            self.cargarMarcas(function() {
              self.cargarModelos()
            })

            self.cargarTipos()
          })
        })
        break
    }
  },

  methods: {
    actualizar: function() {
      var self = this
      var dh = Object.assign({}, self.dh)

      self.bandera_spinner = true

      if(dh.id_estado == null){
        iu.msg.warning('Se debe de seleccionar un estado para DH')
        self.$refs.estado.focus()
        self.bandera_spinner = false
        return
      }

      DhSrv.actualizar(dh).then(response => {
        iu.msg.success('Actualización correcta')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch(e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    atras: function() {
      this.$router.go(-1)
    },

    cargarDh: function(callback) {
      var self = this
      var idDh = this.$route.params.id

      DhSrv.dhJSON(idDh).then(response => {
        self.dh = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el Dispositivo de Hogar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarEstados: function(callback) {
      var self = this
      
      EstadoDhsSrv.estadosJSON().then(response => {
        self.estados = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los estados'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarMarcas: function(callback) {
      var self = this
      
      MarcaDhsSrv.marcasJSON().then(response => {
        self.marcas = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las marcas'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarModelos: function(callback) {
      var self = this
      
      let params = { id_marca: self.dh.id_marca }

      ModeloDhsSrv.modelosJSON(params).then(response => {
        self.modelos = response.data
        if (callback) callback()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los modelos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarTipos: function() {
      var self = this

      TipoDhsSrv.tiposJSON().then(response => {
        self.tipos = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los tipos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    guardar: function() {
      var self = this
      var dh = Object.assign({}, self.dh)

      self.bandera_spinner = true

      if(dh.id_estado == null){
        iu.msg.warning('Se debe de seleccionar un estado para DH')
        self.$refs.estado.focus()
        self.bandera_spinner = false
        return
      }

      DhSrv.guardar(dh).then(response => {
        iu.msg.success('Nuevo DH guardado')
        // Limpieza del formulario
        self.limpiar()
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch(e) {
          mensaje = 'No se pudo guardar el nuevo DH'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    limpiar: function() {
      this.dh = Object.assign({}, this.dhInicial)
      this.modelos = []
    }
  },
}
</script>

<style scoped>

</style>